body {
  background-color: #f6f6f6;
}



.page {
  display: flex;

  .logo {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: #545454;
  }

  .flexGrow {
    flex-grow: 1;
  }

  .product-title {
    font-size: 28px;
    font-weight: 400;
    color: #767676;
  }

  .price {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
  }

  .heading {
    font-size: 16px;
    font-weight: bold;
    color: #555555;
    text-wrap: pretty;
  }

  .para {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    color: #1e1e1e;
    text-wrap: pretty;
  }
}

.order-page {
  background-color: #efefef;
  min-height: 100vh;
}

.step-Number {
  position: absolute;
  left: -120px;
  margin: 0 30px 0 0;
  border: 1px dashed #c17367;
  border-radius: 50%;
  padding: 10px;
  text-align: center;
  line-height: 52px;
  width: 75px;
  height: 75px;
  font-size: 36px;
  font-weight: bold;
  color: #c17367;
}

.order-section {
  background: white;
  border-radius: 10px;
  padding: 16px 30px 40px 30px;
  /* width: 100%; */

  .heading {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    text-wrap: pretty;
  }
}